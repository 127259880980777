<template>
  <div>
    <div 
      :class="{
        'button-prev' : type === 'prev',
        'button-next' : type === 'next',
        'button-next__default-next' : type === 'default-next',
        'button-prev__default-prev' : type === 'default-prev'
      }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path
          fill="#5ed7f2"
          fill-rule="nonzero"
          d="M34.354 25.736l-17.16 17.121-3.48-3.471L29.09 24 13.714 8.614l3.48-3.471 17.16 17.121L36 24z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "next"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.button-prev,
.button-next {
  position: relative;
  width: 256px;
  height: 256px;
  background: $color_grey_1;
  opacity: 0.8;
  border-radius: 50%;
  svg {
    position: absolute;
    top: 104px;
    left: 24px;
  }
  &.hide {
    display: none;
  }
}
.button-prev {
  transform: rotate(180deg);

  &__default-prev {
    transform: rotate(180deg);
    transform-origin: 10% 47%;
  }
}
</style>