import { render, staticRenderFns } from "./SwiperNav.vue?vue&type=template&id=6fdac5a2&scoped=true"
import script from "./SwiperNav.vue?vue&type=script&lang=js"
export * from "./SwiperNav.vue?vue&type=script&lang=js"
import style0 from "./SwiperNav.vue?vue&type=style&index=0&id=6fdac5a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fdac5a2",
  null
  
)

export default component.exports