<template>
  <content-wrapper class="article" v-if="article">
    <div class="photos">
      <swiper-nav type="prev" class="prev" v-show="prevShow" />
      <swiper-nav class="next" type="next" v-show="nextShow" />
      <swiper
        :options="{
          slidesPerView: 'auto',
          touchRatio: 2,
          navigation: {
            nextEl: '.next',
            prevEl: '.prev'
          }
        }"
        ref="slider"
        @slideChange="slideChange()"
      >
        <swiper-slide v-for="(img, index) in article.images" :key="index">
          <img :src="img" />
        </swiper-slide>
      </swiper>
      <div class="bullets">
        <div
          class="bullet"
          :class="{'active':activeSlide==index}"
          v-for="(item, index) in article.images"
          :key="index"
        />
      </div>
    </div>
    <div class="article">
      <h1>{{article.title}}</h1>
      <div v-html="article.description"></div>
    </div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import SwiperNav from "@/components/Parts/SwiperNav";

export default {
  components: {
    ContentWrapper,
    SwiperNav
  },
  data() {
    return {
      article: null,
      prevShow: false,
      nextShow: true,
      activeSlide: 0
    };
  },
  watch: {
    $route: "fetchData"
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.getFact(this.$route.params.id).then(res => {
        this.article = res.data;
        console.log(this.article);
      });
    },
    slideChange() {
      this.activeSlide = this.$refs.slider.swiper.activeIndex;
      this.prevShow = this.$refs.slider.swiper.activeIndex > 0 ? true : false;
      this.nextShow =
        this.$refs.slider.swiper.activeIndex < this.article.images.length - 1
          ? true
          : false;
      console.log();
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.article {
  position: relative;
  .photos {
    .next {
      position: absolute;
      right: -174px;
      top: 274px;
      z-index: 10;
    }
    .prev {
      position: absolute;
      left: -174px;
      top: 274px;
      z-index: 10;
    }
    .swiper-slide {
      width: 100%;
      height: 803px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bullets {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 64px;
      .bullet {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        opacity: 0.3;
        background-color: #f3f3f3;
        margin: 0 12px;
        &.active {
          opacity: 1;
          background-color: $color_blue_2;
        }
      }
    }
  }

  .article {
    width: 832px;
    margin: 0 auto;
    margin-bottom: 120px;
    font-size: 32px;
    line-height: 1.44;
    letter-spacing: 0.45px;
    color: rgba(#fff, 0.8);
    h1 {
      font-size: 82px;
      font-weight: 500;
      letter-spacing: -0.66px;
      line-height: normal;
      color: rgba(#fff, 0.95);
      margin-bottom: 32px;
    }
    h2 {
      font-size: 40px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.23px;
      margin-bottom: 32px;
    }
    p {
      margin-bottom: 32px;
    }
  }
}
</style>