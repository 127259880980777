var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.article)?_c('content-wrapper',{staticClass:"article"},[_c('div',{staticClass:"photos"},[_c('swiper-nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.prevShow),expression:"prevShow"}],staticClass:"prev",attrs:{"type":"prev"}}),_c('swiper-nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.nextShow),expression:"nextShow"}],staticClass:"next",attrs:{"type":"next"}}),_c('swiper',{ref:"slider",attrs:{"options":{
        slidesPerView: 'auto',
        touchRatio: 2,
        navigation: {
          nextEl: '.next',
          prevEl: '.prev'
        }
      }},on:{"slideChange":function($event){return _vm.slideChange()}}},_vm._l((_vm.article.images),function(img,index){return _c('swiper-slide',{key:index},[_c('img',{attrs:{"src":img}})])}),1),_c('div',{staticClass:"bullets"},_vm._l((_vm.article.images),function(item,index){return _c('div',{key:index,staticClass:"bullet",class:{'active':_vm.activeSlide==index}})}),0)],1),_c('div',{staticClass:"article"},[_c('h1',[_vm._v(_vm._s(_vm.article.title))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.article.description)}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }